<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-07-04 15:51:58
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-10-09 10:50:25
 * @FilePath: \official_website\src\views\science\Science.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 学术园地 -->
<template>
  <div id="science">
    <Header></Header>
    <div class="science-content">
      <div class="science-img">
        <div class="science-title wow animate__animated animate__fadeInDown">
          学术园地
        </div>
      </div>
      <div class="science-box">
        <el-tabs
          :tab-position="tabPosition"
          @tab-click="handleClick"
          ref="tabs"
        >
          <el-tab-pane label="文献解读">
            <Decipher></Decipher>
          </el-tab-pane>
          <el-tab-pane label="吉博士">
            <Doctor></Doctor>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import Header from "../componets/Header.vue";
import Footer from "../componets/Footer.vue";
import api from "../../api/api";
import Decipher from "./componets/Decipher.vue";
import Doctor from "./componets/Doctor.vue";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    Decipher,
    Doctor
  },
  data() {
    return {
      tabPosition: "left",
      recruitmentList: [],
      count: 0,
      currentPage: 1
    };
  },
  created() {
    // this.recruitmentList.forEach((item) => {
    //   item.foldFlag = false
    // })
    
    this.getRecruitLists();

    this.getRecruitCounts();
  },
  mounted() {
     this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {},
  methods: {
    resetFlod() {
      this.recruitmentList.forEach((item, index) => {
        item.foldFlag = false
        this.$refs[`recruitList${index}`][0].style.height = "10.5rem"
        this.$refs[`recruitList${index}`][0].style.overflow = "hidden"
      })
    },
    viewMore(index) {
      this.resetFlod()
      this.recruitmentList.[index].foldFlag = !this.recruitmentList.[index].foldFlag
      if(this.recruitmentList.[index].foldFlag) {
        // console.log(this.$refs[`recruitList${index}`][0].style);
        this.$refs[`recruitList${index}`][0].style.height = "initial"
        this.$refs[`recruitList${index}`][0].style.overflow = "initial"
        this.$refs[`recruitList${index}`][0].style.transition = "height 1s linear"
      }
      this.recruitmentList = [...this.recruitmentList]
    },
    retract(index) {
      this.resetFlod()
      // this.recruitmentList.[index].foldFlag = !this.recruitmentList.[index].foldFlag
      if(!this.recruitmentList.[index].foldFlag) { 
        this.$refs[`recruitList${index}`][0].style.height = "10.5rem"
        this.$refs[`recruitList${index}`][0].style.overflow = "hidden"
        this.$refs[`recruitList${index}`][0].style.transition = "height 1s linear"
      }
      this.recruitmentList = [...this.recruitmentList]
    },
    // 获取招聘分类列表
    getRecruitLists(page = 1) {
      api.getRecruitByPage(page).then(res => {
        let result = res.data.models
        // console.log(result);
        result.forEach((item) => {
          if(item.remark) {
            item.demandArea = JSON.parse(item.remark)
            item.area1 = item.demandArea[0].area1
            item.number1 = item.demandArea[0].number1
            item.provice1 = item.demandArea[0].provice1
            item.area2 = item.demandArea[1].area2
            item.number2 = item.demandArea[1].number2
            item.provice2 = item.demandArea[1].provice2
            item.area3 = item.demandArea[2].area3
            item.number3 = item.demandArea[2].number3
            item.provice3 = item.demandArea[2].provice3
          } else {
            item.demandArea = []
          }
          
          item.foldFlag = false
        })

        this.recruitmentList = result

        // this.$nextTick(() => {
        //   new this.$wow.WOW({
        //     boxClass: "wow", // 动画元件css类（默认为wow）
        //     animateClass: "animate__animated", //动画css类（默认为animated）
        //     offset: 0, //到元素距离触发动画（当默认为0）
        //     mobile: true, //在移动设备上触发动画（默认为true）
        //     live: true, //对异步加载的内容进行操作（默认为true）
        //   }).init();
        // });
        
      });
    },
     // 获取招聘总条数
    getRecruitCounts(){
      api.getRecruitCount().then(res => {
        this.count = res.data.models[0]
      }).catch(error => {

      });
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getRecruitLists(val)
    },
    handleClick() {
      this.resettingHeight(this.$refs.tabs.$el)
    },
    resettingHeight($el) {
      this.$nextTick(() => {
        const currentEl = $el.querySelector('.el-tabs__item.is-active'); //掌到当前高亮的
        const barEl = $el.querySelector('.el-tabs__active-bar'); // 到当前高亮时el-tabs active-bar
        barEl.style.transform='translateY('+(currentEl.offsetTop)+'px)'; // 重置transform属性
      })
    }
  },
};
</script>
    
<style lang="scss">
#science {
  .science-content {
    padding-top: 5rem;
    .science-img {
      width: 100%;
      height: 28rem;
      background: url("../../assets/image/science/science.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: relative;
      .science-title {
        position: absolute;
        top: 40%;
        left: 10%;
        transform: translateY(-50%);
        color: #fff;
        font-size: 2.2rem;
        font-family: "SourceHanSansSC-Medium";
      }
    }
    .science-box {
      padding: 3% 8%;
      .el-tabs__item {
        font-size: 1.6rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 1rem;
      }
      .el-tabs__active-bar {
        width: 4px;
      }
      .el-tabs--left .el-tabs__item.is-left {
        background-color: #eee;
        border-radius: 8px;
      }
    }
  }
  .footer {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    padding: 6.25rem 8% 3.125rem;
  }
  @keyframes scaleDraw {
    /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}
</style>